import React, {Component} from 'react';

import Header from '../components/header';
import BlogLink from '../components/bloglink';

class BlogPanel extends Component {
    render() {
        return (
        <div class="u-page-padding">
            <Header title="blog" subtitle="what i have to say." unmountCallback={this.props.unmountCallback}/>
            <div class="body">
                <ul class="bloglist">
                    <BlogLink title="blog post 1" subtitle="the blog posts subtitle" date="11.13.2021" />
                    <BlogLink title="blog post 2" subtitle="the blog posts subtitle" date="11.13.2021" />
                    <BlogLink title="blog post 3" subtitle="the blog posts subtitle" date="11.13.2021" />
                    <BlogLink title="blog post 4" subtitle="the blog posts subtitle" date="11.13.2021" />
                    <BlogLink title="blog post 5" subtitle="the blog posts subtitle" date="11.13.2021" />
                    <BlogLink title="blog post 6" subtitle="the blog posts subtitle" date="11.13.2021" />
                    <BlogLink title="blog post 7" subtitle="the blog posts subtitle" date="11.13.2021" />
                    <BlogLink title="blog post 8" subtitle="the blog posts subtitle" date="11.13.2021" />
                </ul>
            </div>
        </div>
        )
    }
}

export default BlogPanel