import React, { Component } from "react";
import Header from "../components/header";

class MusicPanel extends Component {
    musicSelect(category) {
        document.querySelectorAll(`.music__radio`).forEach(e => e.checked = false);
        document.querySelector(`#${category}-tab`).checked = true;
    }

    render() {
        return (
        <div class="u-page-padding music">
            <input type="radio" class="music__radio" name="music-tabs" id="spotify-tab" value="spotify" checked />
            <input type="radio" class="music__radio" name="music-tabs" id="soundcloud-tab" value="soundcloud" />
            <Header title="music" subtitle="what i hear" unmountCallback={this.props.unmountCallback} tabs={[{label: "spotify", callback: () => this.musicSelect("spotify")}, {label: "soundcloud", callback: () => this.musicSelect("soundcloud")}]} />
            <div class="body">
                <div class="music__body">
                    <div class="music__tab" id="spotify">
                        <h2 class="music__placeholder">Spotify Info!</h2>
                    </div>
                    <div class="music__tab" id="soundcloud">
                        <h2 class="music__placeholder">Soundcloud Info!</h2>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default MusicPanel;