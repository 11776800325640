import React, {Component} from 'react';

class Header extends Component {
    createTabElement(label, callback) {
        let spanStyle = {
            cursor: "pointer"
        }
        return (
        <li class="header-menu__item" id={label + "-menu"}>
            <span onClick={callback} style={spanStyle}>{label}</span>
        </li>
        );
    }

    createTabs(tablist) {
        return tablist.map(tab => this.createTabElement(tab.label, tab.callback))
    }

    render() {
        let tabs = this.props.tabs ? (
        <ul class="header-menu music__menu">
            {this.createTabs(this.props.tabs)}
        </ul>
        ) : (<></>)

        let backArrowStyle = {
            position: "relative",
            display: "inline",
            float: "right"
        }

        return (
        <div class="header">
            <div class="header__main">
                {this.props.title}
            </div>
            <div class="header__separator">
                |
            </div>
            <div class="header__sub">
                {this.props.subtitle}
            </div>
            <div class="header__back-arrow" style={backArrowStyle}>
                <span onClick={this.props.unmountCallback}>X</span>
            </div>
            {tabs}
            {this.props.children}
        </div> 
        );
    }
}

export default Header;