import React, {Component} from "react";
import axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha";

class ContactForm extends Component {
    constructor(props){
        super(props);

        this.state = {
            contentsVisible: false,
            sendername: "",
            senderemail: "",
            subject: "",
            message: "",
            messageSent: false,
            error: null,
            captcha: false
        }

        this.toggleContents = this.toggleContents.bind(this);
    }

    toggleContents() {
        this.setState(prev => ({contentsVisible: !prev.contentsVisible}))
    }

    handleFormSubmission(event) {
        event.preventDefault();
        if (!this.state.messageSent) {
            axios({
                method: 'POST',
                url: process.env.REACT_APP_CONTACT_SCRIPT,
                headers: { 'content-type': 'application/json' },
                data: this.state
            }).then(
                res => this.setState({messageSent: res.data.sent})
            ).catch(
                error => this.setState({error: error.message })
            )
        }
    }

    handleCaptcha(value) {
        console.log("Captcha value:", value);
    }

    render() {
        return(
            <div class="contact-form">
                <h3>Send a Message<span class="form-toggle" onClick={this.toggleContents}>&emsp;{this.state.contentsVisible ? 
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg> : 
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                }</span></h3>
                <form className={this.state.contentsVisible ? "visible" : ""}>
                    <label htmlFor="sendername">Your Name:</label>
                    <input type="text" id="sender-name" name="sendername" placeholder="John Smith" value={this.state.sendername} onChange={e => this.setState({sendername: e.target.value})}></input>

                    <label htmlFor="senderemail">Your Email Address:</label>
                    <input type="email" id="sender-email" name="senderemail" placeholder="you@example.com" value={this.state.senderemail} onChange={e => this.setState({senderemail: e.target.value})}></input>

                    <label htmlFor="subject">Subject</label>
                    <input type="text" id="subject" name="subject" placeholder="You're awesome!" value={this.state.subject} onChange={e => this.setState({subject: e.target.value})}></input>

                    <label htmlFor="message">Message</label>
                    <input type="test" id="message" name="message" placeholder="Say what ya gotta say." value={this.state.message} onChange={e => this.setState({message: e.target.value})}></input>

                    {this.state.messageSent ?
                        <div>Your message has been sent!</div> :
                        <div>
                            {/* <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_KEY} onChange={this.handleCaptcha} /> */}
                            <input type="submit" className="my-3 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow" onClick={e => this.handleFormSubmission(e)} disabled={this.state.messageSent || this.state.captcha}/>
                        </div>
                    }
                    {this.state.error != null &&
                        <div>Error: {this.state.error}</div>
                    }
                </form>
            </div>
        );
    }

}

export default ContactForm;