import React, {Component} from 'react';

import Header from '../components/header';
import InfoCard from '../components/infocard';

const fillerText = "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Atque amet, maxime consectetur neque, voluptate omnis officia ad hic fuga similique tempore sunt incidunt quae. Cum esse vel cupiditate aperiam nihil. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quas minus, tenetur sequi laudantium perspiciatis soluta amet corrupti architecto ad vitae illo. Corrupti vero, ipsam quae commodi accusantium fugit enim nulla? Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, placeat mollitia voluptatum adipisci atque similique perferendis beatae eius excepturi sed fugiat temporibus voluptate vero error aliquam? Tenetur explicabo quidem maxime.";

class TechPanel extends Component {
    render() {
        return (
        <div class="u-page-padding">
            <Header title="tech" subtitle="what i make." unmountCallback={this.props.unmountCallback}/>
            <div class="body">
                <InfoCard title="tech 1" subtitle="info about the tech" body={fillerText} />
                <InfoCard title="tech 2" subtitle="info about the tech" body={fillerText} />
                <InfoCard title="tech 3" subtitle="info about the tech" body={fillerText} />
                <InfoCard title="tech 4" subtitle="info about the tech" body={fillerText} />
                <InfoCard title="tech 5" subtitle="info about the tech" body={fillerText} />
                <InfoCard title="tech 6" subtitle="info about the tech" body={fillerText} />
            </div>
        </div>
        );
    }
}

export default TechPanel;