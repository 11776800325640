import React, {Component} from 'react';

class BlogLink extends Component {
    render() {
        return (
        <li class="post-title">
            <span class="post-title__link">
                <p class="post-title__date">{this.props.date}</p>
                <h2 class="post-title__title">{this.props.title}</h2>
                <h3 class="post-title__subtitle">{this.props.subtitle}</h3>
            </span>
        </li>
        )
    }
}

export default BlogLink;