import { Component } from "react/cjs/react.production.min";

class SocialPanel extends Component {
    render() {
        return (
        <div class="u-page-padding social">
            <input type="radio" class="social__radio" name="social-tabs" id="linkedin-tab" value="linkedin" checked />
            <input type="radio" class="social__radio" name="social-tabs" id="instagram-tab" value="instagram" />
            <input type="radio" class="social__radio" name="social-tabs" id="twitter-tab" value="twitter" />
            <div class="header">
                <div class="header__back-arrow">
                    <a href="index.html">&lAarr;</a>
                </div>
                <div class="header__main">
                    social
                </div>
                <div class="header__separator">
                    |
                </div>
                <div class="header__sub">
                    who i am
                </div>
                <ul class="header-menu social__menu">
                    <li class="header-menu__item">
                        <label for="linkedin-tab" class="social__menu-item" id="linkedin-menu">LinkedIn</label>
                    </li>
                    <li class="header-menu__item">
                        <label for="instagram-tab" class="social__menu-item" id="instagram-menu">Instagram</label>
                    </li>
                    <li class="header-menu__item">
                        <label for="twitter-tab" class="social__menu-item" id="twitter-menu">twitter</label>
                    </li>
                </ul>
            </div>
            <div class="body">
                <div class="social__body">
                    <div class="social__tab" id="linkedin">
                        <h2 class="social__placeholder">LinkedIn Info!</h2>
                    </div>
                    <div class="social__tab" id="instagram">
                        <h2 class="social__placeholder">Instagram Info!</h2>
                    </div>
                    <div class="social__tab" id="twitter">
                        <h2 class="social__placeholder">Twitter Info!</h2>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default SocialPanel;