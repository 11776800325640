import React, { Component } from "react";

import ContactForm from "../components/contactform";

import "../sass/_about.scss"

class AboutPanel extends Component {
    render() {
        return (
        <div class="u-page-padding about">
            <div class="body">
                <div class="">
                    <div class="">
                        <div class="about__close">
                            <span onClick={this.props.unmountCallback}>X</span>
                        </div>
                        <div class="about__resume">
                            <a href="static/resume.pdf">resume &darr;</a>
                        </div>
                        <div class="about__intro about__section">
                            <h2>Hello there!</h2>
                            <p>I’m Khari Ollivierre, a computer engineer with experience in Web technologies, Machine Learning and Simulation development.</p>
                            <p>I help businesses meet their goals through technical consulting and software development.</p>
                            <p>I am also a lifelong musician and I hope that all my work contributes to a beautiful symphony.</p>
                            <p>I am always looking for new and interesting challenges, so if you or your company has a problem to solve, let’s work together!</p>
                        </div>
                        <div class="services about__services about__section hidden">
                            <h2>Services I offer</h2>
                            <div class="services__container">
                                <div class="services__item">
                                    <h3 class="u-margin-bottom-s">Technical Consulting&nbsp;</h3>
                                    <p class="">I can provide your business with full technical analysis based on your needs and size to determine the right technological stack to maximize your effiency and outreach.</p>
                                </div>
                                <div class="services__item">
                                    <h3 class="u-margin-bottom-s">Software Engineering</h3>
                                    <p class="">I can see the creation of your website, app, or software from design to deployment efficiently and extensibly, with security and robustness.</p>
                                </div>
                                <div class="services__item">
                                    <h3 class="u-margin-bottom-s">Technical Support and Maintenance</h3>
                                    <p class="">I can provide support service to your current infrastructure, and help you modernize your business using the latest technologies.</p>
                                </div>
                            </div>
                        </div>
                        <div class="about__contact about__section">
                            <h2>Contact Us</h2>
                            <p>If you would like to talk more, please use the form below to send an email or reach out to me on any of my social platforms. Talk soon!</p>
                            <div class="about__contact--row">
                                <h3>Find me here:</h3>
                                <div class="icon-row">
                                    <a href="https://www.linkedin.com/in/khari-ollivierre/"><span class="iconify inline-block m-3" data-icon="ant-design:linkedin-filled" data-width="32" data-height="32"></span></a>
                                    <a href="https://github.com/selassie-eye"><span class="iconify inline-block m-3" data-icon="akar-icons:github-fill" data-width="32" data-height="32"></span></a>
                                    <span class="iconify inline-block m-3" data-icon="cib:indeed" data-width="32" data-height="32" ></span>
                                    <a href="https://www.upwork.com/freelancers/~01acf268aa2eaca632"><span class="iconify inline-block m-3" data-icon="cib:upwork" data-width="32" data-height="32" ></span></a>
                                </div>
                            </div>
                            <div class="about__contact--row">
                                <h3>And here:</h3>
                                <div class="icon-row">
                                    <a href="https://twitter.com/Selassie_Eye"><span class="iconify inline-block m-3" data-icon="ant-design:twitter-outlined" data-width="32" data-height="32"></span></a>
                                    <a href="https://www.twitch.tv/selassie_eye"><span class="iconify inline-block m-3" data-icon="akar-icons:twitch-fill" data-width="32" data-height="32"></span></a>
                                    <a href="https://www.youtube.com/channel/UCiMnrlTZUJpnRR7Js4ry2Nw"><span class="iconify inline-block m-3" data-icon="ant-design:youtube-filled" data-width="32" data-height="32"></span></a>
                                    <a href="https://www.instagram.com/selassie_eye/"><span class="iconify inline-block m-3" data-icon="ant-design:instagram-filled" data-width="32" data-height="32"></span></a>
                                    <a href="https://soundcloud.com/selassie-oeil"><span class="iconify inline-block m-3" data-icon="akar-icons:soundcloud-fill" data-width="32" data-height="32"></span></a>
                                </div>
                            </div>
                            {/* <div class="about__contact--row">
                                <ContactForm />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default AboutPanel;