import React, {Component} from 'react';

class InfoCard extends Component {
    elementTitle(title) {
        return title.toLowerCase().replace(' ', '-');
    }

    render() {
        return (
        <div class="infocard u-half-width-center u-margin-bottom-m">
            <h1 class="infocard__title">{this.props.title}</h1>
            <h3 class="infocard__sub">{this.props.subtitle}</h3>
            <div class="infocard__expansion">
                <input type="checkbox" class="expand-arrow__checkbox infocard__expansion--checkbox" id={this.elementTitle(this.props.title) + "-toggle"} />
                <div class="expand-arrow infocard__expansion--arrow">
                    <label for={this.elementTitle(this.props.title) + "-toggle"} class="expand-arrow__btn infocard__expansion--btn">
                        <span class="expand-arrow__icon infocard__expansion--icon">&nbsp;</span>
                    </label>
                </div>
                <div class="infocard__expansion--body">{this.props.body}</div>
            </div>
        </div>
        );
    };
}

export default InfoCard;