import React, {Component} from "react";

class MainMenu extends Component {
    constructor(props){
        super(props);

        this.state = {
            smallScreen: window.matchMedia("(max-width: 640px)").matches
        }
    }

    componentDidMount() {
        const handler = e => this.setState({smallScreen: e.smallScreen});
        window.matchMedia("(max-width: 640px)").addEventListener('change', handler);
      }

    render() {
        return (
        <div className="menu">
            <input type="checkbox" className="menu__checkbox hidden" id="menu-toggle" checked={this.props.menuToggle} />
            <label htmlFor="menu-toggle" className="menu__btn" onClick={this.props.toggleMenuCallback}>
                <span className="menu__icon">&nbsp;</span>
            </label>
            <div className={this.state.smallScreen && this.props.pageOpen ? "menu__container hidden" : "menu__container"}>
                <ul className="menu__list">
                    <li className="menu__item"><span className="menu__link" onClick={this.props.aboutCallback}>about</span></li>
                    <li className="menu__item"><a href={this.props.links.github} className="menu__link">github</a></li>
                    <li className="menu__item"><a href={this.props.links.linkedin} className="menu__link">linkedin</a></li>
                    <li className="menu__item"><a href={this.props.links.soundcloud} className="menu__link">music</a></li>
                    <li className="menu__item"><a href={this.props.links.twitter} className="menu__link">twitter</a></li>
                </ul>
            </div>
        </div>
        );
    }
}

export default MainMenu;